import * as React from "react";
import "../styles/main.css";

// markup
const IndexPage = () => {
  return (
    <main className="p-8">
      <h1 className="text-xl font-bold">Vesper Inc.</h1>
      <p>Coming soon.</p>
    </main>
  );
};

export default IndexPage;
